<template>
  <div>
    <h2 class="tg-mobile-header-3 md:tg-desktop-header-3">
      {{ $t('my_searches') }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'Searches'
};
</script>
